

.Header > .mys-pageheader-title{
    font-family: "Helvetica";
    padding-top: 15px;
    display:inline-block;
    color: white;
    padding-left: 30px;
    font-size: 22px;
    font-weight: bold;
}

.Header > .mys-pageheader-content{
    padding: 20px
}

.Header {
    background-color: #ef7721!important;
    height: 50px;
    margin: -8px
}
.Header > .mys-pageheader-extra{
    float:right;
    padding-top: 10px;
    padding-right: 30px;
    font-family: "Helvetica";
}


.loginSpin {
    background-color: rgba(75,77,79,0.39);
    position: fixed!important;
    display: flex!important;
    min-width: 100%;
    /* z-index: 999; */
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    vertical-align: middle;
    margin: 0;
    left: 0!important;
    top: 0!important;
}

.manyosSpin{
    top:0px!important;
    left:0px!important;
    position:relative!important;
    font-size: 200px!important;

}



.p-rr-description{
    font-size:10px;
    margin-left: 50px;
}

.p-add-rr > button{
    margin-left: auto ;
    margin-right: auto;
    width:100%;
    padding-bottom: 20px;
}

.p-add-rr {
    margin-left: auto ;
    margin-right: auto;
    width:100%;
    padding-bottom: 10px;
}

.p-rr-collapse{
    margin-bottom: 10px;
}

